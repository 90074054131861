import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import TextField from '../fields/TextField';
import { useSnackBars } from '../../hooks';
import CREATE_DEBTOR_RATIFICATION_INFORMATION from '../../graphql/debtors/mutations/create-debtor-ratification-information';

const VALIDATION_SCHEMA = Yup.object({
  comment: Yup.string().required('Este campo es obligatorio'),
});

const INITIAL_VALUES = {
  comment: '',
};

const RatificationInformationForm = ({ masterEntityId, onSubmit }) => {
  const { addAlert } = useSnackBars();
  const [createDebtorRatificationInformation] = useMutation(
    CREATE_DEBTOR_RATIFICATION_INFORMATION,
    { variables: { masterEntityId } },
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={(values, { setSubmitting }) => {
        createDebtorRatificationInformation({
          variables: { comment: values.comment },
          onCompleted: () => {
            addAlert({
              id: 'create-debtor-ratification-information-success',
              severity: 'success',
              color: 'success',
              message: 'Nota creada con éxito!',
            });
            onSubmit();
          },
          onError: ({ message }) => {
            addAlert({
              id: 'create-debtor-ratification-information-error',
              severity: 'error',
              color: 'error',
              message,
            });
            setSubmitting(false);
          },
        });
      }}
    >
      {({ resetForm, isSubmitting }) => (
        <Form>
          <TextField
            name="comment"
            label="Comentario"
            placeholder="Escribe acá un comentario..."
            variant="outlined"
            rows={4}
            multiline
            fullWidth
          />
          <Stack direction="row" spacing={3} width={1}>
            <LoadingButton
              variant="outlined"
              onClick={() => {
                resetForm();
                onSubmit();
              }}
              disabled={isSubmitting}
              sx={{ width: 200 }}
            >
              Cancelar
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={isSubmitting}
              sx={{ width: 200 }}
            >
              Deudor NO ratifica
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

RatificationInformationForm.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RatificationInformationForm;
